<template>
  <div id="page-user-edit" class="flex">
    <!-- Owner Details -->

    <!-- First Card -->
    <vx-card class="card w-1\2 p-4" color="primary" elevation="2">
      <h3 style="padding-bottom: 20px;">{{ $t("AdDetails") }}</h3>
      <div class="card-header">
        <div>
          <!-- <h3>{{ Customer.NameAR  }}</h3> -->
        </div>
      </div>
      <div class="mb-4">
        <!-- <p class="text-inherit mb-2">
          <i class="feather icon-user mr-2" style="color: #2196F3;"></i>
          <span style="margin-right: 10px;">{{ OwnerDetails.subOwnerDetails.ownerName }}</span>
        </p> -->
        <p class="text-inherit mb-2">
          <label><span style="margin-right: 10px;">{{phoneNumber }}</span></label>
          <label><i class="feather icon-phone mr-2" style="color: #2196F3;"></i></label>
        </p>
        <p class="text-inherit mb-2">
          <label><span style="margin-right: 10px;">{{address }}</span></label>
          <label><i class="feather icon-map-pin mr-2" style="color: #2196F3;"></i></label>
        </p>
      </div>
      <!-- Buttons for accepting or denying joining request -->
      <div  class="mt-4">
        <div class="flex justify-between">
          <vs-button @click="CheckAcceptAds()" color="success" class="ml-4" :disabled="denySent">
            <i class="feather icon-check"></i> قبول الطلب
          </vs-button>
          <vs-button @click="denyJoining()" color="danger" class="mr-4" :disabled="denySent">
            <i class="feather icon-x"></i> رفض الطلب
          </vs-button>
        </div>
        <textarea v-model="denialReason" v-if="showDenialReason" class="w-full mt-4 custom-textarea" placeholder="سبب الرفض"></textarea>
        <div class="flex justify-end mt-4">
          <vs-button v-if="showDenialReason" @click="sendDenialReason()" color="orange" class="mr-4">
            <i class="feather icon-send"></i> إرسال السبب
          </vs-button>
        </div>
      </div>
      <div class="mt-4">
        <div class="alert alert-warning" role="alert">
          <i class="feather icon-alert-triangle mr-2"></i>
          <span>تم رفض هذا المالك بالفعل.</span>
        </div>
        <br>
        <p>هل ترغب في إضافة هذا المالك؟</p>
        <br>
        <vs-button @click="CheckAcceptOrRejectUnits()" color="success" class="ml-4" :disabled="denySent">
          <i class="feather icon-check"></i> قبول الطلب
        </vs-button>
      </div>
    </vx-card>

    <div class="w-full md:w-2/3 p-4 flex flex-col">
      <!--AdDetails -->
      <div id="data-list-list-view" class="data-list-container mb-4">
        <vs-table v-if="AdsManagements.length > 0" ref="table" id="Scroll" style="padding-top: 5px;" :data="AdsManagements">
          <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
            <h3>{{ $t("AdDetails") }}</h3>
          </div>
          <template slot="thead">
            <vs-th style="font-size: 14px; font-family: almarai; color: green;" sort-key="Name">{{ $t("Name") }}</vs-th>
            <vs-th style="font-size: 14px; font-family: almarai; color: green;" sort-key="Name">{{ $t("AdsCatgeory") }}</vs-th>
            <vs-th style="font-size: 14px; font-family: almarai; color: green;" sort-key="Name">{{ $t("Address") }}</vs-th>
            <vs-th style="font-size: 14px; font-family: almarai; color: green;" sort-key="PhoneNumber">{{ $t("PhoneNumber") }}</vs-th>
            <vs-th v-if="isApproved" style="font-size: 14px; font-family: almarai; color: red;" sort-key="Status">{{ $t("ShowReceivables") }}</vs-th>
            <vs-th v-else style="font-size: 14px; font-family: almarai; color: red;" sort-key="Status">{{ $t("ServiceStatus") }}</vs-th>
          </template>
          <template slot-scope="{ data }">
            <tbody>
              <tr v-for="(tr, indextr) in data" :key="indextr" :class="{ 'selected-row': tr.selected }" @click="toggleRow(tr)">
                <vs-td><p class="product-name font-medium truncate">{{ tr.modelName }}</p></vs-td>
                <vs-td><p class="product-name font-medium truncate">{{ tr.Category.NameAr }}</p></vs-td>
                <vs-td><p class="product-name font-medium truncate"> {{ tr.AddressAR }}</p></vs-td>
                <vs-td><p class="product-name font-medium truncate"> {{ tr.Customer.Phone }}</p></vs-td>
                <vs-td class="whitespace-no-wrap">
                  <router-link :to="{ params: { ID: tr.ID } }">
                  </router-link>
                </vs-td>
                <vs-td class="whitespace-no-wrap">
                  <router-link :to="{ params: { ID: tr.ID } }">
                    <check-icon size="1.8x" class="custom-class" style="color: green;" @click.stop="CheckAcceptUnitBelongingToAnotherOwner(tr, 2)"></check-icon>
                    &nbsp;&nbsp;
                    <x-icon size="1.8x" class="custom-class" style="color: red;" @click.stop="CheckRejectUnit(tr, 3)"></x-icon>
                  </router-link>
                </vs-td>
              </tr>
            </tbody>
          </template>
        </vs-table>
      </div>
    </div>
  </div>
</template>


<script>
import { domain } from "@/gloabelConstant.js";
import moduleAdsManagement from "@/store/AdsManagement/moduleAdsManagement.js";


export default {
  components: {


  },
  data() {
    return {
      baseURL: domain,
      user_not_found: false,
      activeTab: 0,
      AdPayments: {},
      showPayments: false,
      showDenialReason: false,
      denialReason: "",
      denySent: false,
      selectedRow: null,

    };
  },
  computed:{

    AdsManagements() {
        return this.$store.state.AdsManagementList.AdsManagements;
      },
    AdDetails(){
      return this.$store.state.AdsManagementList.AdsManagements;
    },


  },
  methods: {
    acceptJoining(data) {
      debugger;

      //return;
      //break;
      var Id =  data.id;
      this.$store
          .dispatch("OwnersManagementList/ApproveOrDenayUnitOwner", Id)
          .then(() => {
            //const Id = data.unitID;
            this.$router.push(`/OwnersManagement`)
              .then(() => {
                this.approvedsuccess();
              });
          });
    },

    CheckAcceptOrRejectUnits(data ,unitDetails){
debugger;
      var x = unitDetails.filter(x=> x.statusID == 1);

      if(x.length > 0){
        alert (this.$t("PleaseAcceptOrRejectUnits"));
      }
      else {
        this.acceptJoining(data);
      }

    },

//     sendDenialReason(userId, denialReason) {
//       debugger;
//       const obj = {
//         UserId: userId,
//             DenialReason: denialReason
//           };

//     console.log('Sending denial reason:', denialReason);


//     this.$store.dispatch('OwnersManagementList/SendDenialReason', obj)
//     .then(() => {
//                 this.sendNotification();
//               })
//     .then(response => response.json())

//     .catch(error => {
//         console.error('Error:', error);
//     });

//     // Hide the denial reason text area after sending
//     this.showDenialReason = false;
//     // Clear the denial reason
//     this.denialReason = '';
//     this.denySent = true;
// },

    denyJoining() {
      // Show denial reason input
      this.showDenialReason = true;
    },
    CheckAcceptAds(data ){
    this.acceptJoining(data);
    },

    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString(); // Adjust format as needed
    },

    openConfirm(data) {
      this.rowDataForDelete = data;
      const confirmText = data.isPaid ? this.$t("هل انت متأكد انك تريد دفع هذا المستحق") : this.$t("هل انت متأكد انك تريد سحب هذا المستحق");
      this.$vs.dialog({
        type: "confirm",
        color: "success",
        title: this.$t("PaymentConfirmation"),
        text: confirmText,
        accept: this.acceptAlert,
        acceptText: this.$t("Confirm"),
        cancelText: this.$t("Cancel"),
      });
    },




  },
  created() {
    if (!moduleAdsManagement.isRegistered) {
      this.$store.registerModule(
        "AdsManagementList",
        moduleAdsManagement
      );
      moduleAdsManagement.isRegistered = true;
    }
    const IntID =parseInt(this.$route.params.ID);

    const ID =IntID;
    console.log(ID,"ID")
    if (ID != undefined && ID != 0) {
      this.$store
        .dispatch("AdsManagementList/GetItemByID", ID)
        .then(() => {
          console.log(this.AdDetails);
        });
    }
    else if(ID== undefined)
    {
      ID ==0 ;
    }
  },
};
</script>

<style scoped>
.w-1\3 {
  width: 33.33%;
}

.w-2\3 {
  width: 66.66%;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
}

.toggle-switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 24px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(24px);
  -ms-transform: translateX(24px);
  transform: translateX(24px);
}
.custom-textarea {
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  padding: 10px;
  height: 100px;
  resize: vertical;
}
.custom-class {
  cursor: pointer;
}
.custom-textarea:focus {
  outline: none;
  border-color: red;
}
.selected-row {
  background-color: #f0f0f0;
  cursor: pointer;
}

.selected-row:hover {
  background-color: #e0e0e0;
}
.alert {
    border-radius: 4px;
    padding: 1rem;
    font-size: 1rem;
    display: flex;
    align-items: center;
}

.alert-warning {
    background-color: #fff3cd;
    border-color: #ffeeba;
    color: #856404;
}

.alert-warning i {
    color: #856404;
}

.flex-container {
  display: flex;
  justify-content: space-between;
  width: 100%; /* Ensure the container takes the full width */
}

.card {
  flex: 1;
  margin: 0 10px; /* Add some margin to each card for spacing */
  min-width: calc(50% - 20px); /* Each card takes half the width, minus the total margin */
}
.flex {
  display: flex;
  flex-wrap: wrap;
}
.w-full {
  width: 100%;
}

.Ad-card {
  width: 70%;
}
.Ad-details {
  flex: 1;
  margin-bottom: 20px;
}

.unit-details {
  flex: 2;
  margin-bottom: 20px;
}

.payment-details {
  flex: 2;
  margin-bottom: 20px;
}


.card-header h3 {
  margin-bottom: 0;
}


.card-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.custom-textarea {
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  padding: 10px;
  resize: vertical;
}
.custom-class {
  cursor: pointer;
}
.selected-row {
  background-color: #f0f0f0;
}

.image-row {
  display: flex;
  align-items: center;
}

.image-row p {
  margin-right: 20px;
}

.selected-row {
  background-color: #f0f0f0;
}
.p-4 {
  padding: 1rem;
}
.m-4 {
  margin: 1rem;
}
.mr-4 {
  margin-right: 1rem;
}
.ml-4 {
  margin-left: 1rem;
}
.mb-4 {
  margin-bottom: 1rem;
}
.mt-4 {
  margin-top: 1rem;
}
.vs-button {
  display: inline-flex;
  align-items: center;
}
</style>
